import { AssetsAPI } from '@/modules/asset/api';
import { AssetTypeId } from '@/modules/asset/constants';
import { DataType as ConceptType } from '@/modules/data-model/constants/data-type.constants';
import { useAxios } from '@/app/composable';
import { computed, Ref, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { DataType } from '../../constants';

const dataTypesMap = {
    [ConceptType.Boolean]: DataType.Boolean,
    [ConceptType.Time]: DataType.DateTime, // TODO: add time parameter component
    [ConceptType.Date]: DataType.DateTime, // TODO: add date parameter component
    [ConceptType.DateTime]: DataType.DateTime,
    [ConceptType.Double]: DataType.Double,
    [ConceptType.Integer]: DataType.Integer,
    [ConceptType.String]: DataType.String,
    [ConceptType.Binary]: DataType.String, // for binary links we store the minio urll, so they should be rendered as strings
};

export function useAssetStructureParameter(assetId: Ref<number | null>, useParquetStructureIfOptimised = true) {
    const { exec } = useAxios(true);
    const selectedAsset = ref<any>();

    const selectAsset = (id: number | null) => {
        if (id) {
            if (id === selectedAsset.value?.id) return;
            exec(AssetsAPI.getAsset(id, { filterAcquiredFields: true })).then((response: any) => {
                selectedAsset.value = response.data;
            });
        } else {
            selectedAsset.value = null;
        }
    };

    const columns = computed((): {
        value: string;
        label: string;
        selectable: boolean;
        type: string;
        originalType: string;
    }[] => {
        if (selectedAsset.value) {
            const structure = datasetStructure.value || resultStructure.value;
            return (
                structure?.map((s: { field: string; type: string; originalType: string }) => ({
                    value: s.field,
                    label: s.field,
                    selectable: true,
                    type: s.type,
                    originalType: s.originalType,
                })) || []
            );
        }
        return [];
    });

    const columnsWithTypes = computed(() => {
        if (columns.value) {
            return columns.value.reduce((prev, curr) => {
                return R.assocPath([curr.label], curr.type, prev);
            }, {});
        }
        return {};
    });

    const columnTypes = ref<string[]>([]);

    const getSchemaField = (rule: { target: { path: string[]; title: string } }) => {
        return selectedAsset.value.structure?.schema.find((f: { path: string[]; field: string }) =>
            R.equals(
                [...rule.target.path.map((p: string) => p.replaceAll('[]', '')), rule.target.title],
                [...f.path, f.field.split('-')[0]],
            ),
        );
    };

    const datasetStructure = computed(() => {
        if (selectedAsset.value && selectedAsset.value.assetTypeId === AssetTypeId.Dataset) {
            const mappingRules: {
                target: { path: string[]; title: string; type: string; uid: string };
                transformation: { order?: number; multiple?: boolean };
            }[] = selectedAsset.value.processingRules.mappingRules;

            const fieldsToOrder: Record<string, number> = {};
            return mappingRules
                .filter((rule) => !!getSchemaField(rule))
                .map((rule) => {
                    const schemaField: { field: string; type: ConceptType } = getSchemaField(rule);

                    let field = [...rule.target.path, schemaField.field]
                        .join(isOptimised.value && useParquetStructureIfOptimised ? '_' : '.')
                        .replaceAll('[]', isOptimised.value && useParquetStructureIfOptimised ? '_0' : '');

                    if (isOptimised.value && useParquetStructureIfOptimised) {
                        if (rule.transformation.order) {
                            field += `_${rule.transformation.order}`;
                        } else if (rule.transformation.multiple) {
                            fieldsToOrder[field] = fieldsToOrder[field] ? fieldsToOrder[field] + 1 : 1;
                            field += `_${fieldsToOrder[field]}`;
                        }
                    }

                    return {
                        field,
                        type: dataTypesMap[schemaField.type] ?? schemaField.type,
                        originalType: schemaField.type,
                    };
                });
        }
        return null;
    });

    const resultStructure = computed(() => {
        if (selectedAsset.value && selectedAsset.value.assetTypeId === AssetTypeId.Result) {
            return selectedAsset.value.structure?.schema.map((col: { field: string; type: string }) => ({
                field: col.field,
                type: dataTypesMap[col.type],
                originalType: col.type,
            }));
        }
        return null;
    });

    const isOptimised = computed(() => selectedAsset?.value?.parquetStatus === 'optimised');

    const assetStructureForTask = computed(() => {
        if (!selectedAsset.value) return null;
        return {
            columns: columns.value,
            columnsWithTypes: columnsWithTypes.value,
            columnTypes: columnTypes.value,
            parquetStatus: selectedAsset.value.parquetStatus,
        };
    });

    watch(
        () => assetId.value,
        (id: number | null) => {
            selectAsset(id);
        },
        { immediate: true },
    );

    return { assetStructureForTask, selectedAsset };
}
